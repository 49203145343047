<template>
  <v-container class="ma-0 pa-0 d-flex flex-column" fluid>
    <headerr
      class="testa"
      :customBack="true"
      @back="esci()"
      @interventoCambiato="interventoCambiato"
      :switchInterventi="true"
    >
      {{ modalita ? `Posizionamento` : `Accessori` }}
    </headerr>
    <div class="tertiary labelIntervento">
      <v-card-text class="text-center">{{ nome }}</v-card-text>
    </div>
    <div class="ma-0">
      <v-card
        v-if="idPaziente !== undefined"
        elevation="0"
        top
        right
        class="d-flex flex-row justify-space-between"
        fixed
      >
        <v-card-title class="d-flex">
          {{ paziente.nosological }} - {{ paziente.firstName }}
          {{ paziente.lastName }}
        </v-card-title>
        <v-card-actions class="d-flex align-center justify-space-between">
          <v-btn
            class="mr-5"
            color="error"
            :disabled="disabilita"
            @click="setData(3)"
          >
            Annulla
          </v-btn>
          <v-btn
            class="mr-5"
            color="primary"
            :disabled="disabilita"
            @click="setData(2)"
          >
            Termina
          </v-btn>
        </v-card-actions>
      </v-card>

      <v-btn
        v-if="modalita"
        @click="warningsDialog = true"
        fixed
        style="bottom: 70px; rigth: 20px"
        bottom
        right
        x-large
        fab
        color="warning"
      >
        <v-icon>mdi-alert</v-icon>
      </v-btn>
      <v-btn
        v-else
        @click="accessoriDialog = true"
        fixed
        style="bottom: 70px; rigth: 20px"
        bottom
        right
        lar
        x-large
        fab
        color="primary"
      >
        <v-icon>mdi-hammer-screwdriver</v-icon>
      </v-btn>

      <div class="cont3D">
        <!-- <modello :caricaOnCall="true" ref="modello" class="modello"/> -->
        <loader-modello
          :accessoriPresenti="nonColorare"
          :accessori="listaAccessoriCompleti"
          ref="modello"
          @click="(event) => gestioneVisualizzazione(event)"
        />
        <!-- <loader-modello
          :accessoriPresenti="nonColorare"
          :accessori="listaAccessoriCompleti"
          ref="modelloPosizionamento"
          v-show="modalita"
        />
        <loader-modello
          :accessoriPresenti="nonColorare"
          :accessori="listaAccessoriCompleti"
          ref="modelloAccessori"
          v-show="!modalita"
        /> -->
      </div>
    </div>

    <v-dialog max-width="600" v-model="warningsDialog" persistent>
      <v-card>
        <v-card-title class="warning bianco d-flex justify-space-between">
          Warnings
          <div class="scivoloC">
            <v-icon color="white">mdi-format-font-size-decrease</v-icon>
            <v-slider
              hide-details=""
              color="white"
              class="my-0 py-0"
              dense
              :max="25"
              :min="14"
              v-model="fontCard"
            ></v-slider>
            <v-icon color="white">mdi-format-font-size-increase</v-icon>
          </div>
          <v-icon color="white" @click="warningsDialog = false">
            mdi-close
          </v-icon>
        </v-card-title>

        <v-card-text class="mt-4">
          <v-row>
            <v-col v-for="(item, i) in warnings" :key="i" cols="12">
              <v-card
                @click="warnings[i].selezionato = !warnings[i].selezionato"
                :class="
                  warnings[i].selezionato ? 'green lighten-1' : 'red lighten-2'
                "
              >
                <!-- <v-card-title>{{ item.titolo }}</v-card-title> -->
                <v-card-text :style="stile">{{ item.warning }}</v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog max-width="800" v-model="accessoriDialog" persistent>
      <v-card>
        <v-card-title class="primary bianco d-flex justify-space-between">
          Accessori
          <div class="scivoloC">
            <v-icon color="white" class="my-0 py-0">
              mdi-format-font-size-decrease
            </v-icon>
            <v-slider
              hide-details=""
              color="white"
              class="my-0 py-0"
              dense
              :max="25"
              :min="14"
              v-model="fontCard"
            ></v-slider>
            <v-icon color="white" class="my-0 py-0">
              mdi-format-font-size-increase
            </v-icon>
          </div>
          <v-icon color="white" @click="accessoriDialog = false">
            mdi-close
          </v-icon>
        </v-card-title>
        <v-card-text class="mt-4">
          <v-row v-if="accessori">
            <v-col v-for="(item, i) in accessori" :key="i" cols="12">
              <v-card
                :disabled="item.assente"
                @click="item.selezionato = !item.selezionato"
                :color="
                  item.assente
                    ? 'grey lighten-2'
                    : item.selezionato
                    ? 'green lighten-1'
                    : 'red lighten-2'
                "
                class="pa-5"
              >
                <div class="d-flex mb-5">
                  <div>
                    <h3>{{ item.descrizione }}</h3>
                    <p :style="stile">{{ item.codice }}</p>
                  </div>
                  <v-spacer></v-spacer>
                  <v-img
                    v-if="immaginiAccessori[i] != null"
                    max-width="75px"
                    :src="immaginiAccessori[i]"
                    contain
                  ></v-img>
                </div>
                <v-alert
                  v-show="item.assente"
                  border="left"
                  type="error"
                  color="warning"
                >
                  Accessorio non nella disponibilità della struttura.
                  <strong>Contattare il commerciale di zona</strong>
                </v-alert>
                <!-- <div class="ml-5" style="width: 200px">
                  <v-img
                    class="ma-0 pa-0"
                    contain
                    :src="immaginiAccessori[i]"
                  ></v-img>
                </div> -->
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="alertUscita" max-width="400" persistent>
      <v-card>
        <v-toolbar color="error" dark flat>
          <v-toolbar-title>Attenzione!</v-toolbar-title>
        </v-toolbar>
        <v-card-text class="mt-5">
          Stai uscendo senza salvare le modifiche all'intervento
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn @click="alertUscita = false" text class="mb-2">Annulla</v-btn>
          <v-btn color="error" @click="back()" elevation="0" class="mb-2">
            Esci
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogFirma" @click:outside="closed = true">
      <app-firma
        @click="(event) => save(event)"
        :valutatoreInput="valutatore"
        :loading="loading"
        :numberInput="number"
        :operazione="true"
        :closed="closed"
      ></app-firma>
    </v-dialog>
    <v-snackbar v-model="verifyError" color="error" :timeout="3000">
      <h2 style="text-align: center">{{ errorText }}</h2>
    </v-snackbar>
  </v-container>
</template>

<script>
import { Filesystem } from "@capacitor/filesystem";
import { firebaseStore, storage } from "@/plugins/firebase";
import Firma from "../../components/Firma.vue";
//import Modello from "@/components/TheModello.vue";
import loaderModello from "@/components/loaderModello.vue";

export default {
  components: {
    //Modello,
    loaderModello,
    "app-firma": Firma
  },
  data() {
    return {
      DIRECTORY: "DOCUMENTS",
      idIntervento: this.$route.params.idIntervento,

      disabilita: true,
      exit: false,

      alertUscita: false,

      idPaziente: this.$route.query.idPaziente,
      nome: "",
      accessori: [],
      warnings: [],

      fontCard: 14,
      warningsDialog: false,
      accessoriDialog: false,
      modalita: true,
      immagineAccURL: [],

      firma: null,
      valutatore: null,
      loading: null,
      dialogFirma: false,
      invalidPin: false,
      number: null,
      closed: false,
      errorText: "",
      errorStorage: false
    };
  },

  methods: {
    esci() {
      if (this.idPaziente !== null && this.idPaziente !== undefined) {
        var count = 0;

        this.warnings.forEach((warn) => {
          if (warn.selezionato) count++;
        });

        this.accessori.forEach((acc) => {
          if (acc.selezionato) count++;
        });

        if (count > 0) this.alertUscita = true;
        else {
          this.back();
          this.$router.go(-1);
        }
      } else {
        this.$router.go(-1);
      }
    },

    async back() {
      this.exit = true;
      await firebaseStore.collection("pazienti").doc(this.idPaziente).update({
        status: 1
      });
      this.exit = false;
      this.$router.go(-1);
    },
    gestioneVisualizzazione(event) {
      this.disabilita = !event;
      // console.log("event for visualizzato: ", event, this.visualizzato);
    },
    save(event) {
      this.valutatore = event.valutatore;
      this.number = event.number;
      // this.paziente.firma = event.firma;
      this.paziente.postNotes = event.postNote;
      this.checkPin();
    },
    async checkPin() {
      this.loading = true;
      this.invalidPin = false;
      this.errorStorage = false;
      // console.log("paziente in check pin: ", this.paziente)
      try {
        await this.$store.dispatch("pazienti/modificaPaziente", {
          id: this.idPaziente,
          payload: this.paziente,
          operatore: this.valutatore,
          pin: this.number
        });
      } catch (error) {
        if (error.message === "NotAuthorizedError - Pin not valid") {
          this.errorText = "PIN non valido";
          this.invalidPin = true;
        } else {
          this.errorText = "Errore durante il salvataggio dell'intervento";
          this.errorStorage = true;
        }
        console.log(error);
      } finally {
        this.loading = false;
      }
      if (!this.invalidPin && !this.errorStorage) {
        this.dialogFirma = false;
        this.$router.go(-1);
      }
    },
    setData(status) {
      this.paziente.warnings = this.warnings;
      this.updateAccessori();
      this.paziente.status = status;
      this.dialogFirma = true;
      this.closed = false;
      // console.log("dialog firma: ", this.dialogFirma);
      // console.log("paziente in uscita: ", this.paziente);
    },
    updateAccessori() {
      this.accessori.forEach((acc) => {
        if (acc.selezionato) {
          this.paziente.accessori.find(
            (a) => a.codice === acc.codice
          ).selezionato = true;
        }
      });
      this.paziente.accessori.forEach((acc) => {
        this.paziente.accessori.find((a) => {
          if (
            a.codice === acc.codice &&
            a.id !== acc.id &&
            a.codice !== "-" &&
            a.selezionato
          ) {
            acc.selezionato = true;
          }
        });
      });
    },
    blob(b64Data) {
      const sliceSize = 512;
      const byteCharacters = atob(b64Data);
      const byteArrays = [];

      for (
        let offset = 0;
        offset < byteCharacters.length;
        offset += sliceSize
      ) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);

        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
      }

      const blob = new Blob(byteArrays, { type: "application/GLTF" });
      return blob;
    },
    async caricaModelli(configurazione) {
      // console.log("carico modelli");
      try {
        setTimeout(() => {
          this.$refs.modello.caricaConfigurazione(
            configurazione.configurazioneScenaPosizionamento,
            "posizionamentoScene"
          );
          this.$refs.modello.caricaConfigurazione(
            configurazione.configurazioneScenaAccessori,
            "accessoriScene"
          );

          /* this.$refs.modelloPosizionamento.caricaConfigurazione(
            configurazione.configurazioneScenaPosizionamento,
            "posizionamentoScene"
          );
          this.$refs.modelloAccessori.caricaConfigurazione(
            configurazione.configurazioneScenaAccessori,
            "accessoriScene"
          ); */
        }, 100);
      } catch (error) {
        console.log(error);
      }
      // console.log("modelli caricati");
    },
    interventoCambiato(value) {
      this.modalita = value;
      setTimeout(() => {
        this.$refs.modello.cambiaModello(value ? "pos" : "acc");
        this.$refs.modello.ridimensionaCanvas();

        /* this.$refs.modelloAccessori.ridimensionaCanvas();
        this.$refs.modelloPosizionamento.ridimensionaCanvas();
        this.$refs.modelloAccessori.ridimensionaCanvas(); */
      }, 100);

      /* this.$refs.modello.switcha(); */
    }
  },
  computed: {
    verifyError: {
      get() {
        if (this.invalidPin || this.errorStorage) {
          return true;
        } else {
          return false;
        }
      },
      set() {
        return false;
      }
    },
    stile() {
      const dimensione = "font-size:" + this.fontCard + "px;";
      const spaziatura = "letter-spacing: 1px;";
      const interlinea = "line-height: 150%;";
      return dimensione + spaziatura + interlinea;
    },
    intervento() {
      const { idIntervento } = this;
      return this.$store.getters["categorie/interventi"].find(
        ({ id }) => id === idIntervento
      );
    },
    listaAccessoriCompleti() {
      return this.$store.getters["categorie/listaAccessoriCompleti"];
    },
    /* accessoriAccorporati() {
      var accessori = [];
      this.listaAccessoriCompleti.forEach((acc) => {
        if (acc.tipologia !== "Accessorio") return;
        if (!accessori.find((a) => a.codice === acc.codice))
          accessori.push({
            ...acc,
            selezionato: false,
            idFirebase: [acc.idFirebase],
            assente: !this.nonColorare.includes(acc.idFirebase)
          });
        else {
          const accessorio = accessori.find((a) => a.codice === acc.codice);
          accessorio.assente = accessorio.assente
            ? true
            : !this.nonColorare.includes(acc.idFirebase);
          accessorio.idFirebase.push(acc.idFirebase);
        }
      });
      return accessori;
    }, */
    nonColorare() {
      var accessori = [
        ...this.listaAccessoriCompleti
          .filter((a) => a.tipologia !== "Accessorio")
          .map((a) => a.idFirebase),
        ...this.struttura.accessori
      ];
      return accessori;
    },

    struttura() {
      return this.$store.getters["struttura/struttura"];
    },

    paziente() {
      if (this.idPaziente !== null && this.idPaziente !== undefined) {
        return this.$store.getters["pazienti/pazienti"].find(
          (paziente) => paziente.id === this.idPaziente
        );
      } else {
        return null;
      }
    }
  },
  async created() {
    const intervento = this.$store.getters["categorie/intervento"](
      this.idIntervento
    );
    const configurazione = this.$store.getters["categorie/configurazione"](
      intervento.configurazione
    );
    // console.log("paziente: ", this.idPaziente);
    this.nome = intervento.nome;
    var accessoriUsatiConfigurazione = [];
    if (this.idPaziente !== null && this.idPaziente !== undefined) {
      // console.log("carico dati da paziente");
      this.paziente.status = 1;
      this.warnings = this.paziente.warnings;
      // console.log("carico dati da paziente\n\naccessori paziente: ", this.paziente.accessori);
      this.paziente.accessori.forEach((acc) => {
        if (
          !accessoriUsatiConfigurazione.find((a) => a.idFirebase === acc.id)
        ) {
          accessoriUsatiConfigurazione.push({
            ...this.listaAccessoriCompleti.find((a) => a.idFirebase === acc.id),
            selezionato: acc.selezionato
          });
        }
      });

      // this.paziente.accessori;
    } else {
      // console.log("carico dati da configurazione");
      this.warnings = configurazione.warnings.map((warn) => ({
        selezionato: false,
        warning: warn
      }));
      configurazione.configurazioneScenaAccessori.forEach((acc) => {
        if (
          !accessoriUsatiConfigurazione.find(
            (a) => a.idFirebase === acc.originalName
          )
        ) {
          accessoriUsatiConfigurazione.push(
            this.listaAccessoriCompleti.find(
              (a) => a.idFirebase === acc.originalName
            )
          );
        }
      });
      configurazione.configurazioneScenaPosizionamento.forEach((acc) => {
        if (
          !accessoriUsatiConfigurazione.find(
            (a) => a.idFirebase === acc.originalName
          )
        ) {
          accessoriUsatiConfigurazione.push(
            this.listaAccessoriCompleti.find(
              (a) => a.idFirebase === acc.originalName
            )
          );
        }
      });
    }

    accessoriUsatiConfigurazione.forEach((acc) => {
      var selezionato = false;
      if (acc.selezionato) selezionato = true;
      if (acc.tipologia !== "Accessorio") return;
      if (!this.accessori.find((a) => a.codice === acc.codice)) {
        this.accessori.push({
          ...acc,
          selezionato: selezionato,
          idFirebase: [acc.idFirebase],
          assente: !this.nonColorare.includes(acc.idFirebase)
        });
      } else {
        const accessorio = this.accessori.find((a) => a.codice === acc.codice);
        accessorio.assente = accessorio.assente
          ? true
          : !this.nonColorare.includes(acc.idFirebase);
        accessorio.idFirebase.push(acc.idFirebase);
      }
    });
    // console.log("accessori usati", this.accessori);

    //accorpa

    /* this.accessori = this.$store.getters["categorie/accessoriConfigurazione"](
      intervento.configurazione
    ).map(acc=>({
      ...acc,
      selezionato: false
    })) */

    this.immaginiAccessori = [];
    for (const accessorio of this.accessori) {
      if (!accessorio.immagine) {
        this.immaginiAccessori.push(null);
        break;
      }
      /* const url = await storage
        .ref(`accessori/${accessorio.id}.jpg`)
        .getDownloadURL();
      this.immaginiAccessori.push(url); */
    }

    this.caricaModelli(configurazione);
  },
  async mounted() {
    /* console.log("accessori", this.accessoriAccorporati);
    this.accessori = this.accessoriAccorporati; */
    this.immaginiAccessori = [];
    for (const accessorio of this.accessori) {
      if (!accessorio.immagine) {
        this.immaginiAccessori.push(null);
        break;
      }
      try {
        const url = await storage
          .ref(`accessori/${accessorio.idFirebase[0]}.jpg`)
          .getDownloadURL();
        this.immaginiAccessori.push(url);
      } catch (error) {
        console.log("errore", error);
      }
    }
  }
};
</script>

<style>
.modellow {
  width: 100%;
  height: 100%;
}
.labelIntervento {
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.cont3D {
  height: calc(100vh - 130px);
}
.altezza {
  position: absolute;
  height: max(100vh - 100px, 400px);
}
.scivoloC {
  display: flex;
  justify-self: flex-end;
  width: 300px;
}
.testa {
  z-index: 10;
}
</style>
